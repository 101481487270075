var getMenuItems = {

	el: {
		aside: $('aside'),
		dataFilter: $('[data-filter]')
	},

	events: function () {

		var self = this;

		$.ajax({
			type: "GET",
			url: this.el.aside.data('menu-endpoint'),
			dataType: 'json',
			contentType: 'application/json',
			beforeSend: function () {
				self.el.aside.find('ul').append('<li class="loading">A carregar</li>')
			},

		}).done(function (data) {
			$('.loading').remove();
			$.each(data, function (i) {
				var item = self.el.aside.find('ul').append('<li data-level="1" data-id="' + data[i].id + '" class="categorie js-products"><a href="javascript:;">' + data[i].title + '<span>('+ data[i].num_Produtos + ')</span></a></li>');
				if (data[i].categories.length > 0) {
					$.each(data[i].categories, function (j) {
						self.el.aside.find('ul').append('<li class="hidden subcategorie js-products" data-id="' + data[i].categories[j].id + '" data-level="2"><a href="javascript:;">' + data[i].categories[j].title + '<span>'+ data[i].categories[j].num_Produtos +'</a></li>');
						if (data[i].categories[j].categories.length > 0) {
							$.each(data[i].categories[j].categories, function (k) {
								self.el.aside.find('ul').append('<li class="hidden sub-subcategorie js-products"  data-id="' + data[i].categories[j].categories[k].id + '" data-level="3"><a href="javascript:;">' + data[i].categories[j].categories[k].title + '<span>'+ data[i].categories[j].categories[k].num_Produtos + '</a></li>');
							})
						}
					})
				}
				item.on('click', function(e) {
					var itemID = $(e.target).parent().data('id');
					self.el.dataFilter.attr('data-id', itemID)
				})
			})
		})
	},

	init: function () {
		/products/i.test(window.location.href) ? this.events() : ''
	},

}
// getMenuItems.init()